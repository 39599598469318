@import url("./scroller.css");

.panel {
  position: relative;
  &.grey {
    background-color: #f7f7f8;
  }
  &.blue {
    background-color: #393a97;
  }
}
.content {
  min-height: 100vh;
  overflow: auto;
}

.content.split > * {
  width: 50%;
}

#scan-objet-3d {
  color: #fff;
  min-height: 100vh;
  .sf-grid {
    min-height: 100vh;
  }
  ol {
    font-family: Avenir;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }
  #button-ask-quote {
    img {
      color: #393a97;
    }
  }
}

#landing .img-social-network {
  height: 42px;
}

#nos-enjeux {
  background-size: cover;
  background-image: url("../images/panel-computer.jpg");
}

#main-description {
  margin-top: 24px;
  text-align: center;
  font-family: Avenir;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

@media screen and (max-width: 768px) {
  #main-description {
    margin-bottom: 24px;
  }
}

#find-out-more {
  position: absolute;
  left: 50%;
  bottom: 88px;
  transform: translateX(-50%);
}

.panel .button-scroll {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 32px;
}

#services {
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media screen and (max-width: 992px) {
  #services > * {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  #services > * {
    width: 100%;
  }
  #services > *:not(:last-child) {
    border-bottom: 2px solid #f7f7f8;
    margin-left: 26px;
    margin-right: 26px;
  }
}

#services .header {
  height: 150px;
}

#services img {
  display: block;
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  filter: drop-shadow(0 2px 4px rgba(57, 58, 151, 0.25));
}

#services hr {
  display: block;
  width: 108px;
  background-color: #393a97;
  height: 2px;
  border: none;
  margin: 0 auto 16px auto;
}

#services ul {
  list-style: none;
  padding: 0;
}

#services li {
  text-align: center;
}

/* CONTACT */

#nous-contacter .content {
  min-height: auto;
}

@media screen and (max-width: 768px) {
  #nous-contacter {
    display: block;
  }
}

.grey-card {
  background-color: #18192d;
  color: #fff;
}

#button-social-network {
  background-color: #fff;
  padding: 12px 16px 12px 16px;
  box-shadow: 0px 2px 4px rgba(57, 58, 151, 0.25);
  margin-bottom: 16px;
}

#button-social-network img {
  margin-left: 8px;
  width: 30px;
}

#map {
  position: absolute;
  background-color: #1e242b;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#wrapper-contact {
  position: relative;
  padding: 0 16px 32px 16px;
  height: calc(100vh - 46px);
}

.fb-page {
  margin-bottom: 32px;
}

.contact {
  position: relative;
}

.coordinates {
  display: grid;
  grid-template-columns: auto auto;
  padding: 32px;
  gap: 16px;
}

.wrapper-button-social-network {
  grid-column: 1/3;
}
.wrapper-button-social-network > div {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  #wrapper-socials {
    height: 100vh;
  }
  #wrapper-contact {
    height: calc(100vh - 46px);
  }
}

/* RESPONSIVE */

@media screen and (max-width: 768px) {
  .panel {
    height: auto;
  }
  .content.split {
    flex-direction: column;
  }
  .content.split.reverse-mobile {
    flex-direction: column-reverse;
  }
  .content.split > * {
    width: auto;
  }
}

/** TYPO **/

.title {
  margin-top: 48px;
  margin-bottom: 32px;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 71px;
  text-align: center;
  color: #393a97;
}

.title-panel {
  margin-top: 48px;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 71px;
  text-align: center;
  color: #393a97;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

p,
.p-16 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.p-16-light {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #18192d;
}

.p-18 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #18192d;
}

.p-24 {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
}

.p-24-light {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
}
