.list-documents > * {
  margin-right: 24px;
}

#scroller {
  background-color: #f7f7f8;
  display: flex;
}

#scroller > * {
  width: 50%;
}

@media screen and (max-width: 768px) {
  #scroller > * {
    width: 100%;
  }
}

.scroller-block {
  min-height: 100vh;
  max-width: 600px;

  /* border: 1px solid #ccc; */
}

.scroller-block ul {
  list-style: none;
  padding-left: 16px;
}

.scroller-block li {
  display: flex;
}

.scroller-block li::before {
  padding-right: 16px;
  margin-left: 0;
  content: "\2022";
  color: #4296c4;
}

#scroller-container-left {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

#scroller-left {
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 600px;
}

@media screen and (max-width: 768px) {
  #scroller-left {
    display: none;
  }
}

#scroller-left img {
  background-color: #f7f7f8;
  transition: opacity 0.5s ease;
}

.scroller-wrapper-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#title-services-sticky {
  max-width: 550px;
  margin-top: 0;
  margin-bottom: 0;
  top: 0;
  padding-top: 50px;
  padding-bottom: 20px;
  position: sticky;
  background: linear-gradient(
    180deg,
    rgba(247, 247, 248, 1) 0%,
    rgba(247, 247, 248, 1) 75%,
    rgba(247, 247, 248, 0) 100%
  );
}

#scroller-button-container {
  display: inline-block;
  position: sticky;
  bottom: 0;
  z-index: 1;
  left: 100%;
  transform: translateX(-24px);
  padding-top: calc(100vh - 48px - 32px);
  padding-bottom: 32px;
  width: 0;
}
